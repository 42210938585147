








































































































import { mapState } from 'vuex';
import type { PropType } from 'vue';
import type {
  StakingProgram,
  NewStakingProgramData,
  ProgramDialogData,
  SelectItem
} from '@/api/schema';
import * as vr from '@/helpers/validation';
import { StakingProgramType } from '@/api/schema';
import Tooltip from '@/components/tooltip/Tooltip.vue';
import ColorPicker from '@/components/core/ColorPicker.vue';
import { fromPercents, toPercents } from '@/helpers/decimalsHelpers';

const defaultProgramData = {
  type: StakingProgramType.GENERAL,
  name: '',
  rate: 0,
  minAmount: 0,
  maxAmount: 0,
  durationSeconds: 0,
  status: 'ACTIVE',
  limited: false
};

export default {
  name: 'StakingFormDialog',
  components: {
    Tooltip,
    ColorPicker
  },
  data(): any {
    return {
      formIsValid: false,
      programData: {
        ...defaultProgramData
      },
      enableCoefficient: null,
      color: null,
      StakingProgramType
    };
  },
  props: {
    value: {
      type: Boolean as PropType<boolean>,
      default: (): boolean => false
    },
    programItem: {
      type: Object as PropType<StakingProgram>,
      default: (): StakingProgram => ({} as StakingProgram)
    },
    operation: {
      type: String as PropType<string>,
      default: (): string => ''
    },
    data: {
      type: Object as PropType<ProgramDialogData>,
      default: (): ProgramDialogData => {
        return {} as ProgramDialogData;
      }
    }
  },
  computed: {
    ...mapState('Auth', ['tokenSymbol']),
    ...mapState('StakingPrograms', ['programs']),
    programTypes(): SelectItem[] {
      return [
        {
          text: 'General Hold to Earn Program',
          value: StakingProgramType.GENERAL,
          disabled: !!this.programData.id
        },
        {
          text: 'Welcome Hold to Earn',
          value: StakingProgramType.WELCOME,
          disabled: !!this.programData.id || this.welcomeStakingExists
        }
      ];
    },
    header(): string {
      return this.data?.header || '';
    },
    buttonText(): string {
      return this.data?.buttonText || '';
    },
    show: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean): void {
        this.$emit('input', value);
      }
    },
    programRate: {
      get(): number {
        return toPercents(this.programData.rate);
      },
      set(value: number): void {
        this.programData.rate = fromPercents(value);
      }
    },
    isUpdateOperation(): boolean {
      return this.operation === 'update';
    },
    rules(): any {
      return {
        period: [
          vr.required,
          vr.validNumber,
          vr.positiveNumber,
          (v) => vr.gte(v, this.isWelcome ? 0.5 : 1),
          (v) => vr.integer(`${v * (this.isWelcome ? 2 : 1)}`)
        ],
        rate: [
          vr.required,
          vr.validNumber,
          vr.positiveNumber,
          (v) => vr.gte(v, 0.1),
          (v) => vr.lte(v, 30)
        ],
        minAmount: [vr.required, vr.validNumber, vr.positiveNumber],
        maxAmount: [
          (v) => (v ? vr.validNumber(v) : true),
          (v) => (v ? vr.positiveNumber(v) : true),
          (v) => (v ? vr.gte(v, this.programData.minAmount) : true)
        ],
        color: [vr.required, vr.hexColor]
      };
    },
    welcomeStakingExists(): boolean {
      return this.programs.some(
        (item) =>
          item.status !== 'DELETE' && item.type === StakingProgramType.WELCOME
      );
    },
    isGeneral(): boolean {
      return this.programData.type === StakingProgramType.GENERAL;
    },
    isWelcome(): boolean {
      return this.programData.type === StakingProgramType.WELCOME;
    }
  },
  methods: {
    submitHandler(): void {
      const form = this.$refs.form;
      form.validate();

      this.$nextTick((): void => {
        if (this.formIsValid) {
          const submitData = this.createSubmitData();
          this.$emit('submit', submitData);
        }
      });
    },
    createSubmitData(): NewStakingProgramData {
      return {
        type: this.programData.type,
        minAmount: this.programData.minAmount || 0,
        maxAmount: this.programData.maxAmount || null,
        name: this.programData.name,
        rate: this.programData.rate,
        status: this.programData.status,
        colors: [this.color, this.color],
        ...(this.isGeneral && {
          limited: this.programData.limited
        })
      };
    }
  },
  watch: {
    programItem(newValue: StakingProgram): void {
      if (newValue) {
        this.$refs?.form?.reset?.();
        this.$nextTick((): void => {
          this.programData = {
            ...defaultProgramData,
            ...newValue,
            ...(newValue.durationSeconds && {
              durationSeconds: newValue.durationSeconds / 60
            })
          };
          this.color = (newValue?.colors && newValue?.colors[1]) || '#F8964C';
        });
      }
    }
  }
};
