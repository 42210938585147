var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('v-tabs-hash',{staticClass:"mt-1",attrs:{"tabs":_vm.tabItems,"background-color":"transparent","show-arrows":""},scopedSlots:_vm._u([{key:"tab",fn:function(ref){
var text = ref.text;
return [_c('v-tab',[_vm._v(_vm._s(text))])]}}]),model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}})],1),(_vm.isSuperAdmin && !_vm.modal && _vm.showControls)?_c('v-col',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){return _vm.openDialog({}, 'create')}}},[_vm._v("Create Program")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"success"},on:{"click":function($event){return _vm.openDialog({}, 'set-coefficient')}}},'v-btn',attrs,false),on),[_vm._v("Limit Coefficient")])]}}],null,false,4218151478)},[_c('span',[_vm._v("Setup Default Coefficient for Limit Hold to Earn Programs.")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.openDialog({}, 'start-all')}}},'v-btn',attrs,false),on),[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-play-circle')}})],1)]}}],null,false,578706972)},[_c('span',[_vm._v("Start all programs")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"error"},on:{"click":function($event){return _vm.openDialog({}, 'stop-all')}}},'v-btn',attrs,false),on),[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-stop-circle')}})],1)]}}],null,false,12511628)},[_c('span',[_vm._v("Stop all programs")])])],1):_vm._e()],1),_c('v-row',{class:{' mx-n5': _vm.modal }},[_c('v-col',{class:{ 'px-0': _vm.modal }},[_c('v-data-table-with-pagination',{class:{ disabled: _vm.disabledTable },attrs:{"loading":_vm.loading,"headers":_vm.generalTableHeaders,"items":_vm.computedPrograms,"sort-by":"id","must-sort":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_vm._v(_vm._s(item.name))]),_c('v-col',[(item.humanType)?_c('v-chip',{class:item.humanType.toLowerCase(),attrs:{"small":""}},[_vm._v(_vm._s(item.humanType))]):_vm._e()],1)],1)]}},{key:"item.rate",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.rate * 100))]}},{key:"item.tokens",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.roundNumber(item.tokens))+" "+_vm._s(_vm.tokenSymbol))]),_c('br'),_vm._v(_vm._s(_vm.roundNumber(item.tokens * _vm.tokenPrice.price))+" "+_vm._s(_vm.tokenPrice.rateCurrencyTicker))]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.status !== 'DELETE')?_c('v-btn',{staticClass:"mr-1",attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.openDialog(item, 'update')}}},[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1):_vm._e(),(item.status !== 'DELETE')?_c('v-btn',{staticClass:"mr-1",attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.openDialog(item, 'delete')}}},[_c('v-icon',[_vm._v("mdi-delete-outline")])],1):_vm._e()]}}])})],1)],1),_c('StakingConfirmationDialog',{attrs:{"data":_vm.dialogData},on:{"close":_vm.closeDialog,"submit":_vm.confirmationDialogHandler},model:{value:(_vm.confirmationDialog),callback:function ($$v) {_vm.confirmationDialog=$$v},expression:"confirmationDialog"}}),_c('StakingFormDialog',{attrs:{"data":_vm.dialogData,"operation":_vm.dialogOperation,"programItem":_vm.dialogItem},on:{"close":_vm.closeDialog,"submit":_vm.formDialogHandler},model:{value:(_vm.formDialog),callback:function ($$v) {_vm.formDialog=$$v},expression:"formDialog"}}),_c('LimitCoefficientDialog',{on:{"close":_vm.closeDialog,"submit":_vm.coefficientDialogHandler},model:{value:(_vm.coefficientDialog),callback:function ($$v) {_vm.coefficientDialog=$$v},expression:"coefficientDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }