





















import type { PropType } from 'vue';
import { ProgramDialogData } from '@/api/schema';

export default {
  name: 'StakingConfirmationDialog',
  props: {
    value: {
      type: Boolean as PropType<boolean>,
      default: (): boolean => false
    },
    data: {
      type: Object as PropType<ProgramDialogData>,
      default: (): ProgramDialogData => {
        return {} as ProgramDialogData;
      }
    }
  },
  computed: {
    show: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean): void {
        this.$emit('input', value);
      }
    },
    header(): string {
      return this.data?.header || '';
    }
  }
};
