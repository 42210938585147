





































































































































import { mapActions, mapGetters, mapState } from 'vuex';
import humanizeDuration from 'humanize-duration';
import StakingConfirmationDialog from './StakingConfirmationDialog.vue';
import StakingFormDialog from './StakingFormDialog.vue';
import LimitCoefficientDialog from '@/components/LimitCoefficientDialog.vue';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import { roundNumber } from '@/helpers/roundNumber';
import VTabsHash from '@/components/VTabsHash.vue';
import { AxiosError } from 'axios';
import type { ProgramDialogData, StakingProgram } from '@/api/schema';
import { PropType } from 'vue';
import { TTabsItem } from '@/api/schema';

type OperationType =
  | 'delete'
  | 'update'
  | 'create'
  | 'start-all'
  | 'stop-all'
  | 'set-coefficient';

export default {
  name: 'StakingViewPrograms',
  components: {
    LimitCoefficientDialog,
    StakingConfirmationDialog,
    StakingFormDialog,
    VTabsHash
  },
  props: {
    modal: {
      type: Boolean as PropType<boolean>,
      default: (): boolean => false
    }
  },
  data(): any {
    return {
      polling: null,
      formDialog: false,
      confirmationDialog: false,
      coefficientDialog: false,
      dialogItem: {},
      dialogOperation: null,
      dialogData: null,
      tab: 0
    };
  },
  computed: {
    ...mapState('Auth', ['tokenSymbol']),
    ...mapState('TokensInfo', ['tokenPrice']),
    ...mapState('StakingPrograms', ['loading', 'programs']),
    ...mapGetters('Onboarding', ['operatorId']),
    ...mapState('app', ['isSuperAdmin']),
    ...mapState('FeatureFlags', ['features']),
    disabledTable(): boolean {
      return (
        this.features.find((v) => v.name === 'hold_to_earn')?.enabled ===
        false
      );
    },
    showControls(): boolean {
      return this.$role.can.update('h2e') && this.isSuperAdmin;
    },
    generalTableHeaders(): { [key: string]: unknown }[] {
      return [
        { text: 'Name', value: 'name' },
        { text: 'Reward, %', value: 'rate' },
        { text: 'Active Users', value: 'users' },
        { text: 'Amount Locked', value: 'tokens' },
        ...(this.showControls
          ? [{ text: '', value: 'actions', sortable: false }]
          : [])
      ];
    },
    computedPrograms(): StakingProgram[] {
      return this.programs
        .filter((v: StakingProgram) => v.status === 'ACTIVE')
        .map((v: StakingProgram) => ({
          ...v,
          humanType: this.getHumanType(v)
        }));
    },
    tabItems(): TTabsItem[] {
      return [
        {
          id: 'general',
          text: `${this.tokenSymbol} Programs`,
          value: 'general'
        }
      ].filter(Boolean);
    }
  },
  watch: {
    operatorId: {
      handler(newId: number | null): void {
        if (newId) {
          this.getStakingPrograms();
          this.getTokenPriceData();
        }
      },
      immediate: true
    }
  },
  created(): void {
    this.polling = setInterval(() => {
      if (this.operatorId) {
        this.getStakingPrograms();
      }
    }, 20000);
  },
  destroyed(): void {
    clearInterval(this.polling);
  },
  methods: {
    ...mapActions('StakingPrograms', [
      'getStakingPrograms',
      'createStakingProgram',
      'updateStakingProgram',
      'deleteStakingProgram',
      'stopAllStakingProgram',
      'startAllStakingProgram',
      'setDefaultCoefficient'
    ]),
    ...mapActions('TokensInfo', ['getTokenPriceData']),
    getStatusClass(status: string): string {
      if (status === 'DELETE') return 'orange--text';
      if (status === 'ACTIVE') return 'success--text';
      if (status === 'PAUSE') return 'black--text';
      return '';
    },
    humanizeDuration,
    openDialog(item: StakingProgram, method: string): void {
      this.dialogOperation = method;
      this.dialogData = this.getDialogData(this.dialogOperation);
      this.dialogItem = item;
      const dialogType = this.getDialogTypeByOperation(method);
      this[dialogType] = true;
    },
    closeDialog(): void {
      this.dialogItem = {};
      this.dialogOperation = null;
      this.confirmationDialog = false;
      this.formDialog = false;
      this.coefficientDialog = false;
    },
    getDialogTypeByOperation(operation: string): string {
      return operation === 'create' || operation === 'update'
        ? 'formDialog'
        : operation === 'set-coefficient'
        ? 'coefficientDialog'
        : 'confirmationDialog';
    },
    confirmationDialogHandler(): void {
      const { handler } = this.dialogData;
      handler(this.dialogItem.id)
        .then((): void => {
          this.closeDialog();
        })
        .catch((error: AxiosError): void => {
          console.error(error);
          this.closeDialog();
          errorToastMessage(error);
        });
    },
    formDialogHandler(data: StakingProgram): void {
      const { handler } = this.dialogData;
      const dataToSend =
        this.dialogOperation === 'create'
          ? data
          : { id: this.dialogItem.id, data };

      handler(dataToSend)
        .then((): void => {
          this.closeDialog();
        })
        .catch((error: AxiosError): void => {
          console.error(error);
          errorToastMessage(error);
        });
    },
    coefficientDialogHandler(value: number): void {
      const { handler } = this.dialogData;
      handler(value)
        .then((): void => {
          this.closeDialog();
        })
        .catch((error: AxiosError): void => {
          console.error(error);
          this.closeDialog();
          errorToastMessage(error);
        });
    },
    getDialogData(operation: OperationType): ProgramDialogData {
      if (!operation) return;
      switch (operation) {
        case 'create':
          return {
            handler: this.createStakingProgram,
            header: 'Create Program',
            buttonText: 'Create'
          };
        case 'update':
          return {
            handler: this.updateStakingProgram,
            header: 'Edit Program',
            buttonText: 'Update'
          };
        case 'delete':
          return {
            handler: this.deleteStakingProgram,
            header: 'Are you sure you want to delete program?'
          };
        case 'start-all':
          return {
            handler: this.startAllStakingProgram,
            header: 'Are you sure you want to start all programs?'
          };
        case 'stop-all':
          return {
            handler: this.stopAllStakingProgram,
            header: 'Are you sure you want to stop all programs?'
          };
        case 'set-coefficient':
          return {
            handler: this.setDefaultCoefficient,
            header: 'Update default limited program coefficient',
            disclaimer:
              'Set new default coefficient for limited programs. This coefficient will be applied to all users except whose, who have exclusive limited coefficient'
          };
      }
    },
    roundNumber,
    getHumanType(item: StakingProgram): string {
      const type = item.type;

      if (item.limited) {
        return 'Limited';
      }

      if (item.type !== 'WELCOME') {
        return '';
      }

      return type.charAt(0).toUpperCase() + type.slice(1).toLowerCase();
    }
  }
};
